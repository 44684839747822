import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { connect } from "react-redux";

import {
  GoogleOAuthProvider,
  GoogleLogin,
  useGoogleLogin,
} from "@react-oauth/google";
import apiService from "./services/apiService";

const BtnLogin = ({ loginToken }) => {
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        // fetching userinfo can be done on the client or the server
        const userInfo = await apiService.login(tokenResponse.access_token);
        loginToken(userInfo);
      } catch (error) {
        console.log("error:", error);
      }
    },
  });

  return (
    <div>
      <div onClick={() => googleLogin()} className="loginButton"> 
        התחברות
      </div>
    </div>
  );
};

function Login(props) {
  useEffect(() => {
    let userToken = localStorage.getItem("jwt");
    if (userToken) {
      props.loginByToken(userToken);
    }
  }, []);

  const checkUserToken = async (userToken) => {
    const response = await apiService.checkUserToken(userToken);
    if (response?.token) {
      props.updateUser(response.data);
    } else {
      localStorage.removeItem("");
    }
  };
  const handleLogin = async (token) => {
    props.loginByToken(token);
  };

  const logout = () => {
    props.updateUser(undefined);
    localStorage.removeItem("jwt");
  };
  return (
    <div className="login">
      <GoogleOAuthProvider clientId="705172714103-a6d05b0jm768f406ak983b1mf2gc5q4l.apps.googleusercontent.com">
        {props.user?.Name ? (
          <div>
            <div className="row">
              <div>
                <div>
                  שלום <u>{props.user.Name}</u>{" "}
                </div>
                <div style={{ cursor: "pointer" }} onClick={() => logout()}>
                  התנתק
                </div>
              </div>
            </div>
          </div>
        ) : (
          <BtnLogin loginToken={(token) => handleLogin(token)} />
        )}
      </GoogleOAuthProvider>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginByToken: (token) => {
      dispatch({
        type: "LOGIN_BY_TOKEN",
        payload: token,
      });
    },
    updateUser: (user) => {
      dispatch({
        type: "UPDATE_USER",
        payload: user,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
