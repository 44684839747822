import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {AutoSuggest} from 'react-autosuggestions';
import Loader from "./Loader"

import apiService from "./services/apiService";
function AddPlaylistRow(props) {

  const [newPlaylist, setNewPlaylist] = useState("");
  const [showAddPlaylist, setShowAddPlaylist] = useState(false);
const [showLoader,setShowLoader] = useState(false)

  useEffect(() => {
    setShowAddPlaylist(false)
    setNewPlaylist("")
}, [props.song.id]); //  }, []);// 
  const getPlaylists = async () => {
    try {
      const response = await apiService.getAllPlaylists();
      props.updatePlaylists(response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const ifAutoCompleteExist =props.allPlaylists.find(p=>p.playlistName==newPlaylist)
  const clickAddPlaylist = async () => {
    setShowLoader(true)

    const data = {
     playlistId:ifAutoCompleteExist? ifAutoCompleteExist.playlistId:0,
      playlistName: newPlaylist,
      songs: [props.song.id],
    };
    try {
      const response = await apiService.postPlaylists(data);
      props.updateSongs(response);
      getPlaylists();

    } catch (error) {
      console.error("Error:", error);
    }
    
    setShowLoader(false)
  };
  return (
    <div  className="centerRow">
      
      {showLoader && <Loader />}
    {!showAddPlaylist ? <div className="addPlaylists mobileHide"
        onClick={() => (setShowAddPlaylist(true))}
      >
        הוסף לפלייליסט
      </div>:
      <>
    
      <AutoSuggest 
      name=" "
      styles={{  
         searchField: {
        padding: ".5rem",
        border: "2px solid #c8c8c8",
        backgroundColor: "#fff",
        borderRadius: "6px",
        color: ifAutoCompleteExist?"black":"red",
        fontWeight: "normal",
        fontSize: "1.35rem",
        margin: "0 auto",
        width: "7rem",
        focus: {
          color: "#000",
          border: "2px solid #005499",
          outline: "none",
        },
      
      },
      suggestionOption: {
        margin: "0",
        padding: ".5rem",
        fontSize: "1.35rem",
        whiteSpace: "nowrap",
        overflow: "hidden",
        cursor: "default",
        color:"black"
      },}}
                options={props.allPlaylists.map(p=>p.playlistName)}
                handleChange={setNewPlaylist}
                value={newPlaylist}
            />
      
      <div className="addPlaylists"
        onClick={() => clickAddPlaylist()}
      >
        הוסף לפלייליסט
      </div><div className="addPlaylists"
        onClick={() => (setShowAddPlaylist(false))}
      >
        ביטול
      </div></>}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
    allSingers: state.allSingers,
    allPlaylists: state.allPlaylists,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSongs: (allSongs) => {
      dispatch({
        type: "UPDATE_SONGS",
        payload: allSongs,
      });
    },
    updatePlaylists: (playlists) => {
      dispatch({
        type: "UPDATE_ALL_PLAYLISTS",
        payload: playlists,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddPlaylistRow);
