import axios from "axios";
import { ToastContainer, toast,Slide, Zoom, Flip, Bounce } from "react-toastify";

//axios.defaults.baseURL = "https://localhost:44310/api";
axios.defaults.baseURL = "https://music.hngn.co.il/api";

const tokenKey = "token";
axios.interceptors.response.use(null, (error) => {
  if (!error.response || !error.response.data) {
    toast.error(" לא מצליח להתחבר לשרת  ");
    return;
  }

  if (error.response.status === 401) {
  //   toast.error(" חלה שגיאה", {
  //     position: 'bottom-right',
      
  // transition: Slide
  //   });
  console.log("401!!")
    // window.location.replace('/logout');
  }
  if (error.response.status === 400) {
    if (error.response.data !== "WrongPassword") {
      toast.error(error.response.data);
    } else {
      toast.error("שגיאה בנתוני התחברות");
    }
  }
  const expectedError =
    error.response &&
    error.response.status > 401 &&
    error.response.status < 500;
  if (expectedError) {
    toast.error("Unexpected Error ! " + error.response.status.toString());
    alert("Unexpected Error ! " + error.response.status.toString());
  }
  return Promise.reject(error);
});

function seJwt(jwt) {
  axios.defaults.headers.common["Authorization"] = jwt;
}

export function RenewLogin(headers) {
  if (headers && headers["x-auth-token"] && headers["x-auth-token"] !== "") {
    seJwt(headers["x-auth-token"]);
    localStorage.setItem(tokenKey, headers["x-auth-token"]);
  }
}

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  seJwt,
  RenewLogin,
};
