import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import SongRow from "../SongRow";
import EditPlaylist from "../EditPlaylist";
import { Helmet } from 'react-helmet';

function PlaylistScreen(props) {
  
    const { name } = useParams();
const clickstartPlaylist=(p)=>{
  props.updateCurrentPlaylist(p)

props.startPlaylist(songs)
props.updateCurrentSongId(songs[0]?.id)
}
const cuurentPlaylist= props?.allPlaylists?.find(p=>p.playlistName.trim()==name)
const songs =props.allSongs.filter(s=>cuurentPlaylist.songs.includes(s.id))
const title = cuurentPlaylist?.title?cuurentPlaylist?.title:""
const description = cuurentPlaylist?.description?cuurentPlaylist?.description:""
  return ( 
    <div className="centerColumn">
      <Helmet>
  <title>היכל הנגינה:: {title}</title>
  <meta name="description" content={description}/>
</Helmet>
      <h1>
  פלייליסט {" "}
  {cuurentPlaylist?.playlistName} - 
  <button onClick={()=>clickstartPlaylist(cuurentPlaylist)} style={{width:90}}>התחל</button>
  <br/>
 <span style={{fontSize:34}}> {cuurentPlaylist?.title} </span>
  </h1>

  <h3 className="centerRow" style={{width:"70vw"}}>{cuurentPlaylist?.description}</h3>
{/* <EditPlaylist playlist={cuurentPlaylist} /> */}
     <div className="songsOgSinger">
          {songs?.map((s, i) => {
            return <SongRow key={i} item={s} isPlaylist={true} />;
          })}
        </div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    allPlaylists:state.allPlaylists,
    user:state.user,
    allSongs:state.allSongs
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentPlaylist: (p) => {
      dispatch({
        type: "UPDATE_CURRENT_PLAYLIST",
        payload: p,
      });
    },  
    updateCurrentSongId: (songId) => {
      dispatch({
        type: "UPDATE_CURRENT_SONGID",
        payload: songId,
      });
    },  
    startPlaylist: (songs) => {
      dispatch({
        type: "START_PLAYLIST",
        payload: songs,
      });
    },  
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(PlaylistScreen);

