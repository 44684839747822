import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

function ScrollToTop() {
  const location = useLocation();
  const { pathname } = useLocation();


  useEffect(() => {
    ReactGA.initialize('G-WSY1TQTGVY'); 
    ReactGA.pageview(location.pathname + location.search); // אם אתם משתמשים ב-React Router
  }, [location]); // אם אתם משתמשים ב-React Router


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

export default ScrollToTop;
