import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { FaHeart, FaRegHeart, FaHeadphonesAlt } from "react-icons/fa";
import apiService from "../../services/apiService";
import {
  GoogleOAuthProvider,
  GoogleLogin,
  useGoogleLogin,
} from "@react-oauth/google";

const BtnLogin = ({ loginToken }) => {
  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await apiService.login(tokenResponse.access_token);
      loginToken(userInfo);
    },
  });
  return (
    <FaRegHeart onClick={() => googleLogin()} style={{ cursor: "pointer" }} />
  );
};

function LikeLogin(props) {
  const handleLogin = async (user) => {
    localStorage.setItem("userToken", user?.token);
    props.updateUser(user);
  };

  const logout = () => {
    props.updateUser(undefined);
    localStorage.removeItem("userToken");
  };
  return (
    <div className="login">
      <GoogleOAuthProvider clientId="705172714103-a6d05b0jm768f406ak983b1mf2gc5q4l.apps.googleusercontent.com">
        {!props.user && <BtnLogin updateUser={(user) => handleLogin(user)} />}
      </GoogleOAuthProvider>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (user) => {
      dispatch({
        type: "UPDATE_USER",
        payload: user,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LikeLogin);
