import React, { useState, useEffect, useRef } from "react";
import apiService from "./services/apiService";
import { connect } from "react-redux";
import {AutoSuggest} from 'react-autosuggestions';

function AddSong(props) {
  const [songName, setSongName] = useState("");
  const [admin, setAdmin] = useState(undefined);
  const [youtubeId, setYoutubeId] = useState("");
  const [youtubeURL, setYoutubeURL] = useState("");
  const [singers, setSingers] = useState("");
  const [moreSingers, setMoreSingers] = useState("");
  const [volumeList, setVolumeList] = useState([]);
  const [NewsList, setNewsList] = useState([]);
  const [volumeSelected, setVolumeSelected] = useState(0);
    const [newSingers, setNewSingers] = useState("");
  useEffect(() => {
    if (NewsList.length > 0) {
      const News = NewsList[0];
      const parts = News.songName?.split("-");
      setSongName(parts[0]?.trim());
      setSingers(
        parts[1]
          ?.replaceAll("&", ",")
          .replaceAll("/", ",")
          .replaceAll(" ו", ",")
          .replaceAll(" ,", ",")
          .replaceAll(", ", ",")
          .trim()
      );
    }
  }, [NewsList]);
  const updateSongs = async () => {
   const response = await apiService.getAllSongs();
   props.updateSongs(response);
  };
  const getAllVolume = async () => {
    const response = await apiService.getAllVolumes();
    setVolumeList(response);
  };

  const getPermissions = async () => {
    try{
      const response = await apiService.checkPer("Songs");
setAdmin(true)
    }catch(ex){
      setAdmin(false)
    }

  };
  const getAllNews = async (id) => {
    const response = await apiService.getAllNews(id);
    setNewsList(response);
  };

  const getNewsOfSinger = async (singer) => {
    const response = await apiService.getNewsOfSinger(singer);
    setNewsList(response);
  };

  useEffect(() => {
    if(admin){
      if (props.singer) {
        getNewsOfSinger(props.singer.trim());
      } else {
        getAllVolume();
        getAllNews();
      }

    }
  }, [admin]);

  useEffect(() => {
    if(singers?.length>0){

      let tempSingers=singers
      if (tempSingers.startsWith(',')) {
        tempSingers =tempSingers.slice(1); 
      }
      
      if (tempSingers.endsWith(',')) {
        tempSingers= tempSingers.slice(0, -1); 
      }
      setSingers(tempSingers)
    setNewSingers("")
    }}, [singers]);

  useEffect(() => {
    if(admin)
    if (!props.singer) getAllNews(volumeSelected);
  }, [volumeSelected]);

  useEffect(() => {
   getPermissions()
  }, [props.user]);

  const songExist = async () => {
   const response2 = await apiService.newsDone(NewsList[0]?.id);

    if (props.singer) {
      getNewsOfSinger(props.singer.trim());
    } else {
      getAllNews(volumeSelected);
    }
  };

  const handleSubmit = async () => {

    const data = {
      songName,
      youtubeId,
      singers: singers,
    };

    try {
    const response = await apiService.postSong(data);

      if (NewsList[0]?.id) {
        await songExist();
      }
setSingers("")
      setYoutubeId("");
      setMoreSingers("");
      setSongName("");
      setYoutubeURL("");
      updateSongs();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function extractYouTubeVideoId(url) {
    // ביטוי רגולרי לחילוץ ID של סרטון YouTube
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;

    // חיפוש התאמה בדפוס
    const match = url.match(regExp);

    // אם נמצאה התאמה, החזר את ה-ID
    setYoutubeId(match && match[2].length === 11 ? match[2] : null);
  }
  const youtubeExist = props.allSongs.find(
    (p) => p.youTubeId.trim() == youtubeId
  );
  const songNameExist = props.allSongs.filter(
    (p) => p.songName.trim() == songName
  );
  const songNameExistMsg =
    songNameExist &&
    songNameExist.map((s) => (
      <li key={s.youTubeId}>
        {s.singers.map((p) => (
          <span
            style={{
              color: singers?.split(",").includes(p.name.trim())
                ? "red"
                : "black",
            }}
            key={p.name}
          >
            {p.name}
          </span>
        ))}
      </li>
    ));
    
  const ifAutoCompleteExist =props.allSingers.find(s=>s.name==newSingers)

  return (
    <div>
      {!admin ? (
      <></>
      ) : (
        <div  className="addSong">
          <div style={{ fontSize: 25 }}>
            {!props.singer && (
              <>
                בחירה מתוך ערוץ -
                <select
                  style={{ fontSize: 35 }}
                  onChange={(e) => setVolumeSelected(e.target.value)}
                >
                  <option value={0}>ללא</option>
                  {volumeList.map((vol) => (
                    <option key={vol.id} value={vol.id}>
                      {vol.title.trim()}
                    </option>
                  ))}
                </select>{" "}
              </>
            )}
            <div>
              נמצאו {NewsList.length} תוצאות לעריכה | סה"כ שירים במערכת{" "}
              {props.allSongs.length}
            </div>
          </div>
          {NewsList[0] && (
            <div>
              {" "}
              <span style={{ fontSize: 20 }}> {NewsList[0]?.songName}</span>
              {" "}
              <a
                style={{ fontSize: 30 }}
                // href={
                //   "https://www.google.com/search?q=" +
                //   NewsList[0].songName+" youtube.com"
                // }
                href={
                  "https://www.youtube.com/results?search_query=" +
                  NewsList[0].songName
                }
                target="_blank"
              >
                יוטיוב
              </a>
              {" "}
              <a
                style={{ fontSize: 30 }}
                href={
                  "https://www.google.com/search?q=" +
                  NewsList[0].songName+" youtube.com"
                }
             
                target="_blank"
              >
                גוגל
              </a>
          {" "}
              {true && (
                <>
                  <button
                    style={{
                      backgroundColor: "blue",
                      color: "white",size:30
                    }}
                    onClick={() => songExist()}
                  >
                    שיר קיים
                  </button>
                </>
              )}
            </div>
          )}
          <div
            className="centerRow"
            style={{ alignItems: "normal", marginTop: 30 }}
          >

            <div className="centerColumn" style={{ padding: 30,gap:10 }}>
               <label style={{ color: youtubeExist ? "red" : "black" }}>
                כתובת היוטיוב:
                <input
                  type="text"
                  value={youtubeURL}
                  onChange={(e) => {
                    setYoutubeURL(e.target.value);
                    extractYouTubeVideoId(e.target.value);
                  }}
                  style={{ color: youtubeExist ? "red" : "black" }}
                />
              </label>
                           
              <label>
                שם השיר:
                <input
                  type="text"
                  value={songName}
                  onChange={(e) => setSongName(e.target.value)}
                />
              </label>
              {songNameExist.length > 0 && (
                <div>
                  קיים במערכת שירים בשם זה לזמרים:
                  {songNameExistMsg}
                </div>
              )}
              {/* 
        <br /><label>
          מזהה יוטיוב:
          <input
            type="text"
            value={youtubeId}
            onChange={(e) => setYoutubeId(e.target.value)}
          />
        </label> */}    
         
                    <AutoSuggest 
                    name=" "
                    styles={{  
                       searchField: {
                      padding: ".5rem",
                      border: "2px solid #c8c8c8",
                      backgroundColor: "#fff",
                      borderRadius: "6px",
                      color: ifAutoCompleteExist?"black":"red",
                      fontWeight: "normal",
                      fontSize: "1.35rem",
                      margin: "0 auto",
                      width: "7rem",
                      focus: {
                        color: "#000",
                        border: "2px solid #005499",
                        outline: "none",
                      },
                    
                    },
                    suggestionOption: {
                      margin: "0",
                      padding: ".5rem",
                      fontSize: "1.35rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      cursor: "default",
                      color:"black"
                    },}}
                              options={props.allSingers.map(p=>p.name)}
                              handleChange={setNewSingers}
                              value={newSingers}
                          />
                      {newSingers?.length>0&&    <button
                             onClick={() =>
                              setSingers(
                                singers
                                  ? singers.trim() + "," + newSingers.trim()
                                  : newSingers.trim()
                              )
                            }
                           >הוסף</button>}
              {singers?.length>0&&singers?.split(",")?.map((s) => (
                <span
                  key={s}
                  style={{
                    color: props.allSingers.find((si) => si.name == s)
                      ? "black"
                      : "red",background:"#f0f0f0",borderRadius:5,padding:5,cursor:"pointer"
                  }}
                  onClick={()=>setSingers(singers.replaceAll(s,"").replaceAll(",,"))}
                >
                  
                  {s} [X]
                  {", "}
                </span>
              ))} 
            </div>
            <div style={{display:youtubeId && songName?.length>0 && singers?.length>0?"block":"none"}}            >

              <br/>
              <br/>
              
              <img
                src={`https://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`}
                alt="YouTube video thumbnail"
                style={{ height: 100,width:100}}
                className="image"
              />
            <br/>
              {singers} - {songName}
              <br/>
              <button onClick={()=>handleSubmit()}>שלח</button>
            </div>
          </div>
        </div>
      )}{" "}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
    allSingers: state.allSingers,
    user:state.user
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSongs: (allSongs) => {
      dispatch({
        type: "UPDATE_SONGS",
        payload: allSongs,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSong);
