import React, { useState, useEffect, useRef } from "react";
function Loader() {
  
    return (
      <div className="loader-overlay">
      <div className="loader-content">
        <div className="loader-text centerRow">
        <div className="loader-circle"></div>
           <div className="logo ">
           {" "}
            <strong style={{color:"#FD6F01"}}>היכל</strong>
            
            <span style={{ color: "#051a69" }}>הנגינה</span>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    playlist: state.playlist,
    currentSongId: state.currentSongId,
    currentPlaylist:state.currentPlaylist,
    isPlaying:state.isPlaying,
    songTime:state.songTime,
    songDuration:state.songDuration
  };
};


export default Loader;
