import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import AOS from "aos";
import "aos/dist/aos.css"; // ייבוא סגנונות CSS
import SingRow from "../components/Singers/SingerRow";
import AddSong from "../AddSong";
import SingersList from "../components/Singers/SingersList";
import apiService from "../services/apiService";
import { Helmet } from 'react-helmet';

function SingersSreen(props) {
  const { searchTxt } = useParams();
  useEffect(() => {
    AOS.init();
  }, []);


  const title = " רשימת הזמרים החסידים המובילים"
  const description = "הזמרים החסידיים המובילים שמביאים את המוזיקה החסידית ללבבות המאזינים, עם שירים מרגשים וסגנונות מגוונים לכל רגע של רוחניות ושמחה"

  const [countdownDate] = useState(new Date("2025-01-03 16:00:00")); // שנה את התאריך בהתאם
  const [isRunning, setIsRunning] = useState(false);
  const [currentSongID, setCurrentSongID] = useState(undefined);
  const [NewsList, setNewsList] = useState([]);
  const [maxResults, setMaxResults] = useState(25);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [search, setSearch] = useState("");
  const [results, setResults] = useState([]);

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // התאריך עבר, הצג הודעה מתאימה
      return <span>סיימנו!</span>;
    } else {
      return (
        <div>
          <span>{days} ימים </span>
          <span>{hours} שעות </span>
          <span>{minutes} דקות </span>
          <span>{seconds} שניות</span>
        </div>
      );
    }
  };

  useEffect(() => {
    document.title = " היכל הנגינה : כל הזמרים החסידיים";
  }, []);

  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isRunning]);

  const updateList = () => {
//    getAllNews();
    getAllSongs();
  };
  const getAllNews = async () => {
    const response = await apiService.getAllNews();
    setNewsList(response);
  };
  useEffect(() => {
    getAllNews();
  }, []);

  const getAllSongs = async () => {
    try {
      const response = await apiService.getAllSongs();

      let result = response;
      let currentIndex = result.length,
        randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex !== 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [result[currentIndex], result[randomIndex]] = [
          result[randomIndex],
          result[currentIndex],
        ];
      }

      props.updateSongs(result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (!search) {
      setResults(props.allSongs);
    } else {
      const searchLower = search.toLowerCase();

      setResults(
        props.allSongs.filter(
          (song) =>
            song.songName.toLowerCase().includes(searchLower) ||
            song.singers.some((singer) =>
              singer.name.toLowerCase().includes(searchLower)
            )
        )
      );
    }
  }, [search, props.allSongs]);

  return (
    <div className="web">
            <Helmet>
        <title>היכל הנגינה:: {title}</title>
        <meta name="description" content={description}/>
      </Helmet>
      {false && (
        <AddSong News={NewsList[0]} updateList={() => updateList()}></AddSong>
      )}
      {/* <Countdown date={countdownDate} renderer={renderer} /> */}

      {props.allSingers && <SingersList />}
      {/* <div className="bigBanner">
          <div className="vs">
            <div>שמוליק סוכות - הימים שעברתי בחושך</div>
            <div className="or">או</div>
            <div>אלייצור - תמיד אוהב אותי</div>
          </div>
          <strong className="atzbaha">להצבעה</strong>
        </div> */}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
    allSingers: state.allSingers,
    ifAdmin: state.ifAdmin,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSongs: (allSongs) => {
      dispatch({
        type: "UPDATE_SONGS",
        payload: allSongs,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingersSreen);
