import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import { FaHeart,FaRegHeart,FaHeadphonesAlt  } from "react-icons/fa";

function BestSinger({ item,kind }) {
  const thumbnailUrl = `https://img.youtube.com/vi/${item.img?.trim()}/hqdefault.jpg`;

  
  return (
    <div className="bestSinger"   style={{ backgroundImage: `url(${thumbnailUrl})` }}>
          <div className="singerName" style={{fontSize:30}}>
  
       <small>
       {kind=="like"? <FaHeart/>: <FaHeadphonesAlt/>} 
        
       </small> 
        {' '}
        {' '}
        {' '}
          {kind=="like"? "הזמר האהוב":" הלהיט השבועי"} 
            <br/>
          <strong> {item.name} </strong> 
        </div>

    </div>
  );
}

export default BestSinger;
