import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import { FaHeart,FaRegHeart,FaHeadphonesAlt  } from "react-icons/fa";

function SingerRow({ item }) {
  const thumbnailUrl = `https://img.youtube.com/vi/${item.img.trim()}/hqdefault.jpg`;

  return (
    <div className="singleRowSingers"   style={{ backgroundImage: `url(${thumbnailUrl})` }}>
          <h3 className="singerName">
            {item.name} 
        </h3>

    </div>
  );
}

export default SingerRow;
