import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FaPlay, FaTrash } from "react-icons/fa";
import apiService from "../../services/apiService";
import { NavLink } from "react-router-dom";
function PlaylistSongRow(props) {
  const likeClick = async () => {
    if (props.user) {
      const data = { userToken: props.user.token, songId: props.item.id };
      try {
        const response = await apiService.postUserLike(data);
        props.updateSongs(response);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  
const removeSong =()=>{
  
  if(props.currentSongId == props.item.id){
    props.nextSong()
  }
  props.removeFromPlaylist(props.item)

}
  const YoutubeThumbnail = ({ videoId }) => {
    const thumbnailUrl = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    return (
      <>
        <div
          className={
            props.currentSongId == props.item.id
              ? "playlistSongRowActive"
              : "playlistSongRow"
          }
         
        >
          <div className="centerRow">
            <img
              src={thumbnailUrl}
              alt="YouTube video thumbnail"
              className="playlistSongRowImg"
            />

            <div className="overflowEllipsis">
             {props.item.singers.map((s) => (
                        <NavLink
                          key={s.id}
                          to={`/זמרים/${s.name.trim()}`}
                          style={{
                            color:
                              props.currentSongId == props.item.id ? "white" : "black",
                          }}
                        >
                            {s.name.trim()}{" "}
                        </NavLink>
                      ))}{" "}
                      <br />
                      <strong>
                        <NavLink
                          style={{
                            color:
                              props.currentSongId == props.item.id ? "white" : "black",
                          }}
                          to={`/שירים/${props.item.singers[0].name.trim()}/${props.item.songName.trim()}`}
                        >
                          {" "}
                          {props.item.songName}{" "}
                        </NavLink>
                      </strong>
                   
            </div>
          </div>
          <div style={{display:"flex",gap:10}}>
          <FaPlay className="playlistBtn" onClick={()=>props.updateCurrentSongId(props.item.id)}  style={{cursor:"pointer"}}/>
            
          <FaTrash className="playlistBtn" onClick={() => removeSong()}  style={{cursor:"pointer"}} />
         

          </div>
        </div>
      </>
    );
  };

  const [isRunning, setIsRunning] = useState(false);
  const [showYoutube, setShowYoutube] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);

  useEffect(() => {
    let intervalId;

    if (isRunning) {
      intervalId = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [isRunning]);

  useEffect(() => {
    setShowYoutube(false);
  }, [props]);

  const opts = {
    width: "100%",
    height: "150",
    playerVars: {
      autoplay: 1,
    },
  };
  /* <div className="songName">
        <p>זמן שחלף: {elapsedTime} שניות</p>
      </div> */
  return (
    <div className="singleRow">
      <YoutubeThumbnail videoId={props.item.youTubeId.trim()} />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allPlaylists: state.allPlaylists,
    ifAdmin: state.ifAdmin,
    user: state.user,
    currentSongId: state.currentSongId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSongs: (allSongs) => {
      dispatch({
        type: "UPDATE_SONGS",
        payload: allSongs,
      });
    },
    updateCurrentSongId: (songId) => {
      dispatch({
        type: "UPDATE_CURRENT_SONGID",
        payload: songId,
      });
    },
    
    removeFromPlaylist: (song) => {
      dispatch({
        type: "REMOVE_FROM_PLAYLIST",
        payload: song,
      });
    },
    updatePlaylist: (song) => {
      dispatch({
        type: "ADD_TO_PLAYLIST",
        payload: song,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PlaylistSongRow);
