import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
function SongPage(props) {
  const [currentSong, setCurrentSong] = useState({});
  const { singer, song } = useParams();
  
  useEffect(() => {
    const findSong = props.allSongs.find(
      (s) =>
        s.songName.trim() == song &&
        s.singers.find((si) => si.name.trim() == singer)
    );
    if (findSong) {
      setCurrentSong(findSong);
    }
  }, [props.allSongs]);
  
const addToPlaylist=(song,playNow)=>{
  props.updatePlaylist(song)
  if(playNow)
  props.updateCurrentSongId(song.id)
}

   const title = ' '+ currentSong?.singers?.map((s) => s.name + "") + "-" +song
   const description = title +". ניתן לשמוע כעת את השיר ולצפות בקליפ. כעת בהיכל הנגינה -עולם המוזיקה החסידי הכנסו ותהנו"
    const thumbnailUrl = `https://img.youtube.com/vi/${currentSong?.youTubeId?.trim()}/hqdefault.jpg`;

    const getPlaylistName=(id)=>{
      return props.allPlaylists.find(p=>p.playlistId==id)?.playlistName
        }
        const songName =currentSong?.singers?.map((s) => s.name.trim() + "") + "-" +song.trim()
  return (
    <div>
        <Helmet>
    <title>היכל הנגינה:: {title}</title>
    <meta name="description" content={description}/>
    </Helmet>
      <div className="Breadcrumb">
        <NavLink to={`/זמרים`}> כל הזמרים </NavLink>
        {" >>"}
        {currentSong?.singers?.map((s) => {
          return (
            <NavLink to={`/זמרים/${s.name}`} key={s.id}>
              {s.name + ""}
            </NavLink>
          );
        })}
      </div>
      <div className="centerRow">

      <div className="songContainer centerColumn">
      <h1 className="pageTitle">{currentSong?.singers?.map((s) => s.name.trim() + " ") + "- " +song}</h1>
  
<div class="image-container"> 
  
 <img
          src={thumbnailUrl}
          alt={songName}
          className="songPageImg"        />
      
  <div class="play-button2" onClick={()=>addToPlaylist(currentSong,true)}></div>
</div>    <div className="centerRow" style={{fontSize:29}}>פלייליסטים:   
              {currentSong?.playlists
              ?.map(p=>
                <NavLink key={p} to={`/פלייליסטים/${getPlaylistName(p)}`}
                  className="smallPlaylists"
                  >{getPlaylistName(p)+" "}</NavLink>)}
                    </div>
             <div className="centerRow">
             <strong className="smallPlaylists" onClick={()=>addToPlaylist(currentSong,false)}>הוסף לרשימת השמעה</strong>  
             <strong className="smallPlay" onClick={()=>addToPlaylist(currentSong,true)}>    הפעל
          <div className="play-button" ></div>
             </strong>  
             </div>
             {console.log(currentSong)}
             <h3>{currentSong.title}</h3>
             <div>{currentSong.description}</div>
             </div>
                   </div>
      
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
    allPlaylists:state.allPlaylists
  };
};


const mapDispatchToProps = (dispatch) => {
  return {
    updateSongs: (allSongs) => {
      dispatch({
        type: "UPDATE_SONGS",
        payload: allSongs,
      });
    },  
    updateCurrentSongId: (songId) => {
      dispatch({
        type: "UPDATE_CURRENT_SONGID",
        payload: songId,
      });
    },  
    updatePlaylist: (song) => {
      dispatch({
        type: "ADD_TO_PLAYLIST",
        payload: song,
      });
    },  
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(SongPage);
