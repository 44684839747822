import React, { useState, useEffect } from "react";
import { NavLink,useNavigate  } from "react-router-dom";

function SingerRowFull({ item,allSongs }) {
  const thumbnailUrl = `https://img.youtube.com/vi/${item.img.trim()}/hqdefault.jpg`;
  const [songs, setSongs] = useState([]);
  useEffect(() => {
    const songsofSinger = allSongs?.filter((song) =>
      song.singers.some((singer) => singer.id == item.id)
    );
    setSongs(songsofSinger);
  }, [allSongs]);
  const songFormat= song=>{
  return  <>
      <li style={{ fontSize: 20,display:"inline" }}>
            {song.singers.map((s) => (
              <NavLink
                key={s.id}
                to={`/זמרים/${s.name.trim()}`}
             
              >
                {s.name.trim()}{" "}
              </NavLink>
            ))}{" - "}
       
            
    <strong>
    <NavLink
    
      to={`/שירים/${item.name.trim()}/${song.songName.trim()}`}
    >
      {" "}
      {song.songName}{" "}
    </NavLink>
    </strong>
    </li>
    </>
  }
  return (
    <div className="playlistSongRow2">
      <div  className="singleRowSingers"  style={{ backgroundImage: `url(${thumbnailUrl})` ,height:"100%",width:150}}>
      <h3 style={{margin:0}} className="singerName2">
            {item.name} 
        </h3>
      </div>
          <div style={{padding:10,margin:0}}>
         
         <div>
          <strong  style={{ fontSize: 20,display:"inline" }}>השיר האחרון:</strong>
          <br/>
          {songs[0]&&songFormat(songs[0])}
         </div>
         <div
         >
          <hr/>
          <ul>
          {songs?.slice().sort((a, b) => b.played - a.played).slice(0,5).map(s=>songFormat(s))}
          </ul>    </div>
        </div>
    </div>
  );
}

export default SingerRowFull;
