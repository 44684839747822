import { ToastContainer, toast,Slide, Zoom, Flip, Bounce } from "react-toastify";
import { connect } from "react-redux";
import { useEffect } from "react";
import { NavLink,useLocation } from "react-router-dom";


const ChangeSong = ({ song }) => (
        <div className="centerRow" style={{width:"100%", fontFamily:"open sans hebrew",fontSize:12,color:"black", justifyContent:"space-between",alignItems:"end",margin:0,height:30}}>

<img
    src={ `https://img.youtube.com/vi/${song?.youTubeId?.trim()}/hqdefault.jpg`}
    alt="YouTube video thumbnail"
    className="playlistSongRowImg"
                style={{height:30}}
  />
    <div>
<br/>{song?.singers.map((s) => s.name.trim() + " ")} 
      <br />
      
      <strong>
      {song?.songName}
      </strong>
  
    </div>
      <NavLink to="/רשימת-השמעה" >
         <div className="smallPlaylists" style={{fontSize:10, textAlign:"center",padding:7}}> 
    לצפייה בקליפ 
    {" >>"}
    </div>
</NavLink>
  </div>
);

function Notifications(props) {
      const location = useLocation();
  
  const currentSong=props.playlist?.find(p=>p.id==props.currentSongId) 
  const displayMsg = () => {
   
    toast.info('Hey 👋!', {
      position: 'bottom-right',
      
  transition: Slide
    });
  };
  
  useEffect(() => {
    if(props.currentSongId && decodeURIComponent(location.pathname.split('/')[1])!=="רשימת-השמעה"){
toast.clearWaitingQueue()
      toast.info(<ChangeSong song={currentSong}/>,{
        position: 'bottom-center',
    transition: Slide
});
    }
  }, [props.currentSongId]);
  
  return (
    <div>
      <ToastContainer icon={false} limit={1}  rtl closeButton={false}  autoClose={3000}/>
    </div>
  );
}



const mapStateToProps = (state) => {
  return {
    user: state.user,
    playlist: state.playlist,
    currentSongId: state.currentSongId,
    currentPlaylist:state.currentPlaylist,
    allSongs:state.allSongs
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentSongId: (songId) => {
      dispatch({
        type: "UPDATE_CURRENT_SONGID",
        payload: songId,
      });
    },  
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(Notifications);
