import React, { useState, useEffect, useRef } from "react";
import apiService from "./services/apiService";
import { connect } from "react-redux";
import {AutoSuggest} from 'react-autosuggestions';
import randomStrings from "./services/randomStrings";
function EditSinger(props) {
  const [admin, setAdmin] = useState(undefined);
  const [singer, setSinger] = useState(props.singer);
  const [parentSinger, setParentSinger] = useState("");
  const [statusSelected, setStatusSelected] = useState(undefined);
  const getPermissions = async () => {
    try{
      const response = await apiService.checkPer("Songs");
setAdmin(true)

}catch(ex){
      setAdmin(false)
    }

  };

  useEffect(() => {
    if(admin){
         

    }
  }, [admin]);

  
  useEffect(() => {
      getPermissions()  
  }, [props.user]);
  
  useEffect(() => {
    if(props.singer){
const tempSinger= props.singer
if(props.songs.length==1){
  tempSinger.defaultImg=props.songs[0]?.youTubeId
}
      setSinger(props.singer)
      setParentSinger(props.singer?.name?.trim())
    }

  }, [props.singer,props.songs]);
  const parentSelected = props.allSingers.find(s=>s.name==parentSinger)
  useEffect(() => {
    if(statusSelected && props.singer)
    getTxt(statusSelected) 
  else if(!props.singer?.description && !statusSelected && props.singer?.id){
setStatusSelected("רגיל")
  }  
  }, [props.singer,statusSelected]);
  const getTxt= async (status)=>
    {
  
    updateSinger("description",await randomStrings.getDescriptionForSinger(singer?.name?.trim(),status))}
  const handleSubmit = async () => {
    if(!parentSelected){
      alert("חסר ParentId")
  return;

    }
      if(!singer.defaultImg){
        alert("חסר תמונה")
  return;
}
    const data = {
      id:singer.id,
      singerName:singer.name?.trim(),
      description:singer.description?.trim(),
      img:singer.defaultImg?.trim(),
      parentId:parentSelected.id,
    };
    try {
   const response = await apiService.editSinger(data);
    
   props.updateSongs(response)
   setStatusSelected(undefined)
   props.nextSinger()
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const updateSinger = (key,value) => {
    setSinger({ ...singer, [key]:value });
  };

  return (
    <div>
      {!admin ? (
      <>NO!!!</>
      ) : (
        <div  className="addSong">
          <div> שם הזמר: 
            <input value={singer?.name?.trim()} onChange={e=>updateSinger("name",e.target.value)}/>
          </div> 
              <AutoSuggest 
                                name=" "
                                styles={{  
                                   searchField: {
                                  padding: ".5rem",
                                  border: "2px solid #c8c8c8",
                                  backgroundColor: "#fff",
                                  color: !parentSelected?"red":parentSelected.id==props.singer?.id?"black":"blue",
                                  fontWeight: "normal",
                                  fontSize: "1.35rem",
                                  focus: {
                                    color: "#000",
                                    border: "2px solid #005499",
                                    outline: "none",
                                  },
                                
                                },
                                suggestionOption: {
                                  margin: "0",
                                  padding: ".5rem",
                                  fontSize: "1.35rem",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  cursor: "default",
                                  color:"black"
                                },}}
                                          options={props.allSingers.map(p=>p.name)}
                                          handleChange={e=>setParentSinger(e)}
                                          value={parentSinger}
                                      />
                              
            <select value={statusSelected} onChange={e=>setStatusSelected(e.target.value)}>
              {randomStrings.getStatuses().map(s=>
                <option key={s}>{s}</option>
              )}
            </select>
            <br/>

{console.log(singer)}
            <div> תיאור לגוגל: 
            <br/>            <br/>
            <textarea rows={3} style={{width:"70%",color:props?.singer?.description?.trim()==singer?.description?.trim()?"grey":"black"}}  value={singer?.description?.trim()}  onChange={e=>updateSinger("description",e.target.value)}/>
          </div>
            <div> בחירת תמונה: 
              <br/>
  {!singer?.defaultImg ? props.songs?.map(s=>
  <>
                <img
                onClick={()=>updateSinger("defaultImg",s.youTubeId?.trim())}
                key={s.youTubeId}
                src={ `https://img.youtube.com/vi/${s.youTubeId?.trim()}/hqdefault.jpg`}
                alt="YouTube video thumbnail"
                className="playlistSongRowImg"
                            style={{height:80,margin:5,border:s.youTubeId?.trim()==singer?.defaultImg?.trim()?"2px solid red":"none"}}
              />
              </>
              ):     <img
              onClick={()=>updateSinger("defaultImg",undefined)}
              src={ `https://img.youtube.com/vi/${singer?.defaultImg?.trim()}/hqdefault.jpg`}
              alt="YouTube video thumbnail"
              className="playlistSongRowImg"
              style={{height:130}}

            />}
            
          </div>
          <button onClick={()=>handleSubmit()}>
            עריכה
            </button>
           </div>
      )}{" "}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
    user:state.user,
    allSingers:state.allSingers
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSongs: (allSongs) => {
      dispatch({
        type: "UPDATE_SONGS",
        payload: allSongs,
      });
    },
  };
};


export default connect(mapStateToProps,mapDispatchToProps)(EditSinger);
