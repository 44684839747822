import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink,useLocation } from "react-router-dom";

function Navigation(props) {
  const location = useLocation();
const [firstSegment,setFirstSegment]= useState("")

    useEffect(() => {
  setFirstSegment(decodeURIComponent(location.pathname.split('/')[1])) ;

    }, [location]);
  return ( 
    <div className="navigation">
    <div className="mobileHide">
    <NavLink to="/"> 
דף הבית
</NavLink>
</div>     <div className={firstSegment=="זמרים"?"activeNavigation":""}>
    <NavLink to="/זמרים"> 
זמרים
</NavLink>
</div>
<div className={firstSegment=="שירים"?"activeNavigation":""}>

    <NavLink to="/שירים"> 
שירים
</NavLink>
</div>
<div className={firstSegment=="פלייליסטים"?"activeNavigation":""}>

<NavLink to="/פלייליסטים"> 
פלייליסטים
</NavLink>
</div> 

</div>

  );
}


const mapStateToProps = (state) => {
  return {
    allPlaylists:state.allPlaylists,
    user:state.user,
    allSongs:state.allSongs,
    allSingers:state.allSingers
  };
};


export default connect(mapStateToProps)(Navigation);

