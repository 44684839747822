import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FaHeart,FaRegHeart,FaHeadphonesAlt   } from "react-icons/fa";
import axios from "axios";

function MSGBox(props) {

  return ( 
    <div className="bottomMSG">
<div className="bottomTitle">
{">> "}
{props.titleTxt}
</div>
<textarea className="bottomTextarea"/>
<div className="flexToEnd">
<button className="bottomBtn">לשליחה</button></div>
    </div>
  );
}


const mapStateToProps = (state) => {
  return {
    allPlaylists:state.allPlaylists,
    ifAdmin:state.ifAdmin,
    user:state.user,
    currentSongId:state.currentSongId
  };
};


export default connect(mapStateToProps)(MSGBox);

