import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams, NavLink, useNavigate } from "react-router-dom";
import SongRow from "../SongRow";
import Search from "../Search";
import BestSong from "../components/Songs/BestSong";
import MSGBox from "../components/Bottom/MSGBox";
import AddSong from "../AddSong";
import { Checkbox } from "@mui/material";
function SongsSreen(props) {
  const [songs, setSongs] = useState([]);
  const [maxResults, setMaxResults] = useState(25);
  const { searchTxt } = useParams();
  const [search, setSearch] = useState("");
  const [filterWithoutPlaylists, setfFlterWithoutPlaylists] = useState(true);

  useEffect(() => {
    if (searchTxt) {
      setSearch(searchTxt);
    }
  }, [searchTxt]);
  useEffect(() => {
    const filterdList = props.allSongs?.filter((s) =>
      s.songName?.includes(search)
 // && (filterWithoutPlaylists? s.playlists.length==0 :true)
    );
    if(filterdList.length>0){
      shuffleSongs(filterdList);
    }
    else{
      setSongs([])
    }
    setMaxResults(24);    
  }, [search, props.allSongs,filterWithoutPlaylists]);

  const bestLikes =
    props.allSongs.length > 0 &&
    props.allSongs?.reduce((max, song) =>
      max.likes > song.likes ? max : song
    );

  const bestPlayed =
    props.allSongs.length > 0 &&
    props.allSongs?.reduce((max, song) =>
      max.played > song.played ? max : song
    );

  useEffect(() => {
    document.title = " היכל הנגינה : כל השירים החסידים";
  }, []);
  const shuffleSongs=(result)=>{    
    if(!result)
      result=[...songs]

      let currentIndex = result.length,
        randomIndex;
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [result[currentIndex], result[randomIndex]] = [
          result[randomIndex],
          result[currentIndex],
        ];
    setSongs(result)  
      }
  }
  return (
    <div className="centerColumn">
      <h1>כל השירים</h1>
      {/* <AddSong /> */}
      <div className="centerRow">
        {bestLikes && <BestSong item={bestLikes} kind="like" />}
        {bestPlayed && <BestSong item={bestPlayed} kind="played" />}
      </div>
      <Search searchTxt={search} updateSearchTxt={(e) => setSearch(e)} clickBtn={()=>undefined} />
      <>
      <button onClick={()=>shuffleSongs()}> 
        סדר שירים אקראי
      </button>
    <div>
    <label>
        <input type="checkbox" checked={filterWithoutPlaylists} onChange={()=>setfFlterWithoutPlaylists(!filterWithoutPlaylists)} style={{margin:0,background:"green",width:30}}/>
     להראות רק ללא פלייליסטים
      </label>
    </div>
        <div className="songsOgSinger">
          {songs?.slice(0, maxResults).map((s, i) => {
            return <SongRow key={i} item={s} />;
          })}
        </div>
      </>
      {maxResults < songs.length && (
        <div
          className="btnAllSingSongs"
          onClick={() => setMaxResults(maxResults + 25)}
        >
          הצג עוד תוצאות{" "}
        </div>
      )}
      <MSGBox titleTxt="לא מצאת את השיר שחיפשת? אולי יש לך משהו אחר להגיד לנו?" />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
    allSingers: state.allSingers,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentSongId: (songId) => {
      dispatch({
        type: "UPDATE_CURRENT_SONGID",
        payload: songId,
      });
    },  updateSongs: (songs) => {
      dispatch({
        type: "UPDATE_SONGS",
        payload: songs,
      });
    },
    startPlaylist: (songs) => {
      dispatch({
        type: "START_PLAYLIST",
        payload: songs,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SongsSreen);
