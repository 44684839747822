import React, { useState, useEffect, useRef } from "react";
import apiService from "./services/apiService";
import { connect } from "react-redux";
import {AutoSuggest} from 'react-autosuggestions';
import randomStrings from "./services/randomStrings";
function EditPlaylist(props) {
  const [admin, setAdmin] = useState(undefined);
  const [playlist, setPlaylist] = useState(undefined);
  console.log(playlist)
  const getPermissions = async () => {
    try{
      const response = await apiService.checkPer("playlists");
setAdmin(true)

}catch(ex){
      setAdmin(false)
    }

  };

  useEffect(() => {
    if(admin){
         

    }
  }, [admin]);

  
  useEffect(() => {
    getPermissions()  
}, [props.user]);



useEffect(() => {
 if(props.playlist){
  setPlaylist(props.playlist)
 } 
}, [props.playlist]);

  const handleSubmit = async () => {

    const data = {
      id:playlist.playlistId,
      title:playlist.title,
      description:playlist.description,
      playlistName:playlist.playlistName
    };
    console.log(data)
    try {
   const response = await apiService.editPlaylist(data);
    
   props.updatePlaylists(response)
   props.nextSinger()
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const updatePlaylist = (key,value) => {
    setPlaylist({ ...playlist, [key]:value });
  };

  return (
    <div>
      {!admin ? (
      <>NO!!!</>
      ) : (
        <div  className="addSong">
     <div>כותרת:
     <br/>

      <input value={playlist?.title} onChange={e=>updatePlaylist("title",e.target.value)}/>
     </div>
     <br/>
     <div>
תיאור:
<br/>

<textarea rows={5} style={{width:500}} value={playlist?.description} onChange={e=>updatePlaylist("description",e.target.value)}/>
      </div>
          <button onClick={()=>handleSubmit()}>
            עריכה
            </button>
           </div>
      )}{" "}
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
    user:state.user,
    allSingers:state.allSingers
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updatePlaylists: (playlists) => {
      dispatch({
        type: "UPDATE_ALL_PLAYLISTS",
        payload: playlists,
      });
    },
  };
};


export default connect(mapStateToProps,mapDispatchToProps)(EditPlaylist);
