import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FaHeart, FaRegHeart, FaHeadphonesAlt } from "react-icons/fa";
import axios from "axios";
import { NavLink } from "react-router-dom";
import apiService from "../../services/apiService";

function BestSong(props) {
  const likeClick = async () => {
    if (props.user) {
      const data = { userToken: props.user.token, songId: props.item.id };
      try {
        const response = await apiService.postUserLike();
        props.updateSongs(response);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const addToPlaylist = (song, playNow) => {
    props.updatePlaylist(song);
    if (playNow) props.updateCurrentSongId(song.id);
  };

  const getPlaylistName = (id) => {
    return props.allPlaylists.find((p) => p.playlistId == id)?.playlistName;
  };
  const thumbnailUrl = `https://img.youtube.com/vi/${props.item.youTubeId.trim()}/hqdefault.jpg`;

  return (
    <div
      className="bestSinger"
      style={{ backgroundImage: `url(${thumbnailUrl})` }}
    >
      <div className="singerName" style={{ fontSize: 30 }}>
        <small>
        {props.kind=="like"? "הזמר האהוב":" הלהיט השבועי"} 
        </small>
        <br />
        {props.item.singers.map((s) => (
          <NavLink key={s.id} to={`/זמרים/${s.name.trim()}`}>
            {s.name.trim()}{" "}
          </NavLink>
        ))}
        {" - "}
        <strong>
          <NavLink
            to={`/שירים/${props.item.singers[0].name.trim()}/${props.item.songName.trim()}`}
          >
            {" "}
            {props.item.songName}{" "}
          </NavLink>
        </strong>
        <br />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allPlaylists: state.allPlaylists,
    ifAdmin: state.ifAdmin,
    user: state.user,
    currentSongId: state.currentSongId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSongs: (allSongs) => {
      dispatch({
        type: "UPDATE_SONGS",
        payload: allSongs,
      });
    },
    updateCurrentSongId: (songId) => {
      dispatch({
        type: "UPDATE_CURRENT_SONGID",
        payload: songId,
      });
    },
    updatePlaylist: (song) => {
      dispatch({
        type: "ADD_TO_PLAYLIST",
        payload: song,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BestSong);
