import httpService from "./httpService";
import JwtDecode from "jwt-decode";


const tokenKey = "jwt";

export async function postUserLike(data) {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(tokenKey)}` },
  };
  const { data: result } = await httpService.post(
    "/Songs/userLike",
    data,
    config
  );
  return result;
}

export async function editSinger(data) {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(tokenKey)}` },
  };
  const { data: result } = await httpService.put(
    "/Songs/EditSinger",
    data,
    config
  );
  return result;
}

export async function editPlaylist(data) {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(tokenKey)}` },
  };
  const { data: result } = await httpService.put(
    "/playlists/EditPlaylist",
    data,
    config
  );
  return result;
}

export async function postSong(data) {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(tokenKey)}` },
  };
  const { data: result } = await httpService.post("/Songs", data, config);
  return result;
}
export async function postPlaylists(data) {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(tokenKey)}` },
  };
  const { data: result } = await httpService.post("/playlists", data, config);
  return result;
}
export async function postHistory(data) {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(tokenKey)}` },
  };
  const { data: result } = await httpService.post(
    "/Songs/history",
    data,
    config
  );
  return result;
}

export async function getAllPlaylists() {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(tokenKey)}` },
  };
  const { data: result } = await httpService.get("/playlists", config);
  return result;
}

export async function getAllSongs() {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(tokenKey)}` },
  };
  const { data: result } = await httpService.get("/songs", config);
  return result;
}
export async function getAllVolumes() {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(tokenKey)}` },
  };
  const { data: result } = await httpService.get("/Songs/volume", config);
  return result;
}
export async function login(google) {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(tokenKey)}` },
  };
  const { data: result } = await httpService.get(
    "/Users/Login/" + google,
    config
  );
  return result;
}

export async function checkPer(per) {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(tokenKey)}` },
  };
  const { data: result } = await httpService.get(
    "/songs/check/" + per,
    config
  );
  return result;
}

export async function newsDone(newsId) {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(tokenKey)}` },
  };
  const { data: result } = await httpService.get(
    "/Songs/newsdone/" + newsId,
    config
  );
  return result;
}
export async function checkUserToken(token) {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(tokenKey)}` },
  };
  const { data: result } = await httpService.get(
    "/Users/GetUserByUserToken/" + token,
    config
  );
  return result;
}

export async function getAllNews(volumeId) {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(tokenKey)}` },
  };
  const { data: result } = await httpService.get(
    `/songs/news${volumeId ? `/${volumeId}` : ""}`,
    config
  );
  return result;
}

export async function getNewsOfSinger(singerName) {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem(tokenKey)}` },
  };
  const { data: result } = await httpService.get(
    `/songs/newsOfSinger/${singerName}`,
    config
  );
  return result;
}

export default {
  editSinger,
  editPlaylist,
  getAllPlaylists,
  getAllSongs,
  getAllNews,
  postUserLike,
  postHistory,
  postPlaylists,
  getAllVolumes,
  getNewsOfSinger,
  login,
  checkUserToken,
  newsDone,
  postSong,
  checkPer
};
