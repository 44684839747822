import React, { useState, useEffect, useRef } from "react";
import SingerRow from "./SingerRow";
import { NavLink,useParams } from "react-router-dom";
import { connect } from "react-redux";
import Search from "../../Search";
import BestSinger from "./BestSinger";
function SingersList(props) {
  const { searchTxt } = useParams();
  const [search,setSearch]= useState("")
    const [maxResults, setMaxResults] = useState(24);
  
  
  useEffect(() => {
    if(searchTxt){
      setSearch(searchTxt)
    }
    setMaxResults(24)
  }, [searchTxt]);
  const results=search.length>0?props.allSingers.filter(s=>s.name.includes(search)):props.allSingers
  const bestLikes = props.allSingers.length>0&& props.allSingers?.reduce((max, song) => max.likes > song.likes ? max : song);

  const bestPlayed =props.allSingers.length>0&& props.allSingers?.reduce((max, song) => max.played > song.played ? max : song);
 
  return (
    <>
    <h1>זמרים</h1>
<div className="centerRow">
<NavLink to={`/זמרים/${bestLikes.name}`}>
{bestLikes&&<BestSinger item={bestLikes}  kind="like"/>} 
         </NavLink> 
         <NavLink to={`/זמרים/${bestPlayed.name}`}>
         {bestPlayed&&<BestSinger item={bestPlayed}   kind="played"/>} 
         </NavLink> 
     </div>
        <Search searchTxt={search} updateSearchTxt={e=>setSearch(e)} clickBtn={()=>undefined}/>
      

 <h3>{results.length}  תוצאות</h3>
    <div className="singers">
  
      {results?.slice(0,maxResults).map((singer) => (
      <NavLink key={singer.id} to={`/זמרים/${singer.name}`}>
          <SingerRow item={singer}  key={singer.id} />
         </NavLink> 
      ))}
  
    </div>
    {maxResults < results.length && (
            <div
              className="btnAllSingSongs"
              onClick={() => setMaxResults(maxResults + 25)}
            >
              הצג עוד תוצאות{" "}
            </div>
          )}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
    allSingers: state.allSingers,
  };
};
export default connect(mapStateToProps)(SingersList);
