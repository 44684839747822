import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import SingersScreen from "./screens/SingersScreen";
import SingerScreen from "./screens/SingerScreen";
import SongPage from "./SongPage";
import Login from "./Login";
import { NavLink } from "react-router-dom";
import backgroundImage from "./img/Background.jpg";
import Playlist from "./Playlist";
import AllplaylistScreen from "./screens/AllplaylistScreen";
import PlaylistScreen from "./screens/PlaylistScreen";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import FirstScreen from "./screens/FirstScreen";
import Navigation from "./screens/Navigation";
import VideoScreen from "./screens/VideoScreen";
import SongsSreen from "./screens/SongsSreen";
import Notifications from "./components/Notifications";
import Loader from "./Loader";
import apiService from "./services/apiService";
import ScrollToTop from "./ScrollToTop.js";
import { Helmet } from 'react-helmet';

function App(props) {
  const description = "היכל הנגינה הוא אתר מוזיקה חסידית עשיר ומגוון, המציע אלפי שירים מכל הסוגים: מוזיקה חסידית מקורית, מוזיקה מזרחית אותנטית, ניגוני שבת קסומים, הלהיטים החסידיים החדשים ביותר ועוד. האתר מאפשר למשתמשים להאזין ברצף לשירים על פי זמר, וכן ליהנות ממגוון רחב של אפשרויות האזנה נוספות.";

  const shuffleSongs=(result)=>{
      let currentIndex = result.length,
        randomIndex;
      while (currentIndex !== 0) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;
        [result[currentIndex], result[randomIndex]] = [
          result[randomIndex],
          result[currentIndex],
        ];
      }
      return result
    
    }
  const getAllSongs = async () => {
    try {
      const response = await apiService.getAllSongs();

      
      props.updateSongs(response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getPlaylists = async () => {
    try {
      const response = await apiService.getAllPlaylists();
      props.updatePlaylists(response);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getAllSongs();
    getPlaylists();
  }, []);

  useEffect(() => {
    if (
      props.allSongs.length > 0 &&
      props.allPlaylists.length > 0 &&
      !props.currentSongId
    ) {
      if (localStorage.getItem("currentSongId")) {
        props.updateCurrentPlaylist(
          JSON.parse(localStorage.getItem("currentPlaylist"))
        );
        props.startPlaylist(JSON.parse(localStorage.getItem("playlist")));
        props.updateCurrentSongId(
          parseInt(localStorage.getItem("currentSongId"))
        );
      } else {
        const hitPlaylist = props.allPlaylists.find((h) => h.playlistId == 2); //להיטים
        const songs = shuffleSongs(props.allSongs?.filter((s) =>
          hitPlaylist.songs.includes(s.id))
        );
        props.updateCurrentPlaylist(hitPlaylist);
        props.startPlaylist(songs);
        props.updateCurrentSongId(songs[0]?.id);
      }
    }
  }, [props.allSongs, props.playlist]);

  useEffect(() => {
    document.title = "היכל הנגינה - עולם המוזיקה החסידית";
  }, []);

  const showLoader = !(
    props.allSongs.length > 0 && props.allPlaylists.length > 0
  );
  return (
    <Router>      <Helmet>
    <title>היכל הנגינה - דף הבית</title> {/* כותרת רצויה */}
    <meta name="description" content={description} />
  </Helmet>


<ScrollToTop />
      {showLoader && <Loader />}
      <div className="App">
        <div className="upWeb">
          <NavLink to="/">
            {" "}
            <div className="logo">
              <strong style={{ color: "#FD6F01" }}>היכל</strong>
              <span style={{ color: "#051a69" }}>הנגינה</span>
            </div>
          </NavLink>

          <div className="centerRow">
            <Navigation />
            <Login />
          </div>
        </div>
        {props.playlist?.length > 0 &&
          props.currentSongId &&
          props.allSongs && <Playlist />}
        <Routes>
          
          <Route path="/" element={<FirstScreen />} />
          <Route path="/login" element={<div>Login</div>} />
          <Route path="שירים" element={<SongsSreen />} />
          <Route path="זמרים" element={<SingersScreen />} />
          <Route path="רשימת-השמעה" element={<VideoScreen />} />
          <Route path="זמרים/:page" element={<SingerScreen />} />
          <Route path="זמרים/חיפוש/:searchTxt" element={<SingersScreen />} />
          <Route path="זמרים/חיפוש/" element={<SingersScreen />} />
          <Route path="שירים/:singer/:song" element={<SongPage />} />
          <Route path="פלייליסטים" element={<AllplaylistScreen />} />
          <Route
            path="פלייליסטים/חיפוש/:searchTxt"
            element={<AllplaylistScreen />}
          />
          <Route path="פלייליסטים/חיפוש/" element={<AllplaylistScreen />} />
          <Route path="שירים/חיפוש/:searchTxt" element={<SongsSreen />} />
          <Route path="שירים/חיפוש/" element={<SongsSreen />} />
          <Route path="פלייליסטים/:name" element={<PlaylistScreen />} />
        </Routes>
        <Notifications />
      </div>
    </Router>
  );
}
const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
    playlist: state.playlist,
    allPlaylists: state.allPlaylists,
    currentSongId: state.currentSongId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSongs: (allSongs) => {
      dispatch({
        type: "UPDATE_SONGS",
        payload: allSongs,
      });
    },
    updatePlaylists: (playlists) => {
      dispatch({
        type: "UPDATE_ALL_PLAYLISTS",
        payload: playlists,
      });
    },
    updateCurrentPlaylist: (p) => {
      dispatch({
        type: "UPDATE_CURRENT_PLAYLIST",
        payload: p,
      });
    },
    updateCurrentSongId: (songId) => {
      dispatch({
        type: "UPDATE_CURRENT_SONGID",
        payload: songId,
      });
    },
    startPlaylist: (songs) => {
      dispatch({
        type: "START_PLAYLIST",
        payload: songs,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
