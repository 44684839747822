import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams ,NavLink,useNavigate} from "react-router-dom";
import SongRow from "../SongRow";
import Search from "../Search";
import AddSong from "../AddSong";
import apiService from "../services/apiService";
import EditSinger from "../EditSinger";
import { Helmet } from "react-helmet";
function SingerScreen(props) {
  const [search,setSearch]= useState("")
  const [toAddShow,setToAddShow]= useState(false)
  const navigate = useNavigate();
  const { page } = useParams();
  const [songs, setSongs] = useState([]);
  const [singer, setSinger] = useState({});
  const [showAddBtn, setShowAddBtn] = useState(false);
  useEffect(() => {
    const songsofSinger = props.allSongs?.filter((song) =>
      song.singers.some((singer) => singer.name.trim() == page.trim())
    );
    setSongs(songsofSinger);
const firstSing =props.allSongs?.find((song) =>
  song.singers.some((singer) => singer.name.trim() == page.trim())
)
    setSinger(firstSing?.singers?.find((singer) => singer.name.trim() == page.trim()))
  }, [props.allSongs,page]);
const getNextSinger=()=>{
  return props?.allSingers[props.allSingers?.findIndex(s=>s.name==page)+1]
}

const navigateToNextSinger=()=>{
 navigate("/זמרים/"+getNextSinger()?.name) 
}


const CallApiToCheckPer=  async ()=>{
  try{
    const result = await apiService.checkPer("Songs")
  setShowAddBtn(true)
  }
  catch(error){
console.log(error)
  }
}
useEffect(() => {
  CallApiToCheckPer()
}, [props.user]);

const clickstartPlaylist=()=>{
props.startPlaylist(songs)
props.updateCurrentSongId(songs[0]?.id)
props.updateCurrentPlaylistName(`שירי ${page} ברצף`);
}

    const title = ' '+page +" - "+songs.length + " שירים";
  const description =singer?.description?singer?.description:""

  return (
    <div>
    <Helmet>
<title>היכל הנגינה:: {title}</title>
<meta name="description" content={description}/>
</Helmet>
      <div className="Breadcrumb">
        <NavLink to={`/זמרים`}> 
          <u>
          כל הזמרים </u></NavLink>
        {" >>"}
        {page}
{" "}
{false&&
        <div className="btnAllSingSongs" onClick={()=>setToAddShow(!toAddShow)}>הוסף שיר</div>}
    {false&&
      <AddSong singer={page}/>}    
      </div>
      
      {/* <EditSinger singer={singer} songs={songs} nextSinger={()=>navigateToNextSinger()}/> */}
      {/* <NavLink to={`/זמרים/${getNextSinger()?.name}`}> 
      <h3 style={{display:"inline-block"}}>למעבר לזמר הבא:     
      {getNextSinger()?.name}</h3></NavLink>*/}
      <div className="centerRow"> 
      <h1 className="pageTitle">{page}</h1>
      <h3>({songs.length } שירים)</h3>
      <div className="btnAllSingSongs"
      onClick={()=>clickstartPlaylist()}>        
         לשמיעת כל השירים ברצף</div>

      </div>
      <div className="centerRow">
        
      <Search searchTxt={search} updateSearchTxt={e=>setSearch(e)} clickBtn={()=>undefined}/>
      </div>
      <>
        <div className="songsOgSinger">
          {songs?.filter(s=>s.songName.includes(search)).map((s, i) => {
            return (
             
                  <SongRow key={i} item={s} />
            );
          })}
        </div>
      </>
      <NavLink to={`זמרים/${getNextSinger()?.name}`}> 
      <h3 style={{display:"inline-block"}}>למעבר לזמר הבא:     
      {getNextSinger()?.name}</h3></NavLink>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
    allSingers:state.allSingers,
    user:state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentSongId: (songId) => {
      dispatch({
        type: "UPDATE_CURRENT_SONGID",
        payload: songId,
      });
    },  
    startPlaylist: (songs) => {
      dispatch({
        type: "START_PLAYLIST",
        payload: songs,
      });
    },  
    updateCurrentPlaylistName: (p) => {
      dispatch({
        type: "UPDATE_CURRENT_PLAYLIST_NAME",
        payload: p,
      });
    },
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(SingerScreen);
