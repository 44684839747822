import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { legacy_createStore as createStore } from "redux";
import { Provider } from "react-redux";
import { jwtDecode } from "jwt-decode";

const jwtTokenToUser = (token) => {
  if (token) {
    try {
      localStorage.setItem("jwt", token);
      return JSON.parse(jwtDecode(token).props);
    } catch (error) {
      console.error("Invalid token:", error);
      localStorage.removeItem("jwt");
    }
  }
};
const root = ReactDOM.createRoot(document.getElementById("root"));
const initState = {
  allSongs: [],
  allSingers: [],
  allPlaylists: [],
  user: undefined,
  playlist: [],
  currentSongId: undefined,
  currentPlaylist: undefined,
  playlistName: "",
};
const saveInLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};
function getUniqueSingers(allSongs) {
  const uniqueSingers = [];
  if (allSongs.length > 0) {
    allSongs.forEach((song) => {
      song.singers.forEach((singer) => {
        // if(singer.defaultImg)
        //   return;
        const existingSinger = uniqueSingers.find((s) => s.id === singer.id);
        if (!existingSinger) {
          uniqueSingers.push({
            id: singer.id,
            name: singer.name.trim(),
            img: song.youTubeId.trim(),
            likes: song.likes.length,
            played: song.played,
            songs:1
          });
        } else {
          existingSinger.likes += song.likes.length;
          existingSinger.played += song.played;
          existingSinger.songs += 1;

        }
      });
    });
   // return uniqueSingers.sort((a, b) => b.songs - a.songs);
    return uniqueSingers.sort((a, b) => b.played - a.played);
  }
}


const reducer = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_SONGS":
      state = {
        ...state,
        allSongs: action.payload,
        allSingers: getUniqueSingers(action.payload),
      };
      break;
    case "UPDATE_ALL_PLAYLISTS":
      state = {
        ...state,
        allPlaylists: action.payload,
      };
      break;
    case "UPDATE_USER":
      state = {
        ...state,
        user: action.payload,
      };
      break;
    case "LOGIN_BY_TOKEN":
      state = {
        ...state,
        user: jwtTokenToUser(action.payload),
      };
      break;
      case "ADD_TO_PLAYLIST":
        const songExist = state.playlist.find((p) => p.id == action.payload.id);
        if (!songExist) {
          state = {
            ...state,
            playlist: [...state.playlist, action.payload],
          };
          saveInLocalStorage("playlist", [...state.playlist, action.payload]);
        }
        break;
      
    case "REMOVE_FROM_PLAYLIST":
        state = {
          ...state,
          playlist: state.playlist.filter(p=>p.id!=action.payload.id) // action.payload],
        };
          saveInLocalStorage("playlist", [...state.playlist, action.payload]);

      break;
        
    case "START_PLAYLIST":
      state = {
        ...state,
        playlist: action.payload,
      };
      saveInLocalStorage("playlist", action.payload);
      break;
    case "UPDATE_CURRENT_SONGID":
      state = {
        ...state,
        currentSongId: action.payload,
      };
      saveInLocalStorage("currentSongId", action.payload);

      break;
    case "UPDATE_CURRENT_PLAYLIST":
      state = {
        ...state,
        currentPlaylist: action.payload,
      };
      saveInLocalStorage("currentPlaylist", action.payload);
      break;
      
  case "UPDATE_CURRENT_PLAYLIST_NAME":
    state = {
      ...state,
      playlistName:action.payload,
      currentPlaylist:undefined
    };
    saveInLocalStorage("currentPlaylistName", action.payload);
    localStorage.removeItem("currentPlaylist");
    break;
}
  
  
  return state;
};

const store = createStore(reducer);
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
