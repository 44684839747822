import React, { useState, useEffect } from "react";
import YouTube from "react-youtube";
import AddPlaylistRow from "./AddPlaylistRow";
import { connect } from "react-redux";
import { FaHeart, FaRegHeart, FaHeadphonesAlt } from "react-icons/fa";
import axios from "axios";
import { NavLink } from "react-router-dom";
import LikeLogin from "./components/Login/LikeLogin";
import apiService from "./services/apiService";

function SongRow(props) {
  const likeClick = async () => {
    if (props.user) {
      const data = { userToken: props.user.token, songId: props.item.id };
      try {
        const response = await apiService.postUserLike(data);
        props.updateSongs(response);
        // await checkUserToken();
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
    }
  };
  const addToPlaylist = (song, playNow) => {
props.updateCurrentPlaylistName(`רשימה אישית`);

    props.updatePlaylist(song);
    if (playNow) props.updateCurrentSongId(song.id);

  };

  const getPlaylistName = (id) => {
    return props.allPlaylists.find((p) => p.playlistId == id)?.playlistName;
  };
  const thumbnailUrl = `https://img.youtube.com/vi/${props.item.youTubeId.trim()}/hqdefault.jpg`;
  const songName =props.item?.singers?.map((s) => s.name.trim() + "") + "-" +props.item?.songName.trim()

  return (
    <div>
      <div
        className={
          props.currentSongId == props.item.id
            ? "songRowActive"
            : "songRow"
        }
      >
        <div className="centerRow" >
          <img
            src={thumbnailUrl}
            alt={songName}
            className="playlistSongRowImg"
          />

          <div style={{display:"flex",flexDirection:"column",gap:2,}}>
           <div> 
            {props.item.singers.map((s) => (
              <NavLink
                key={s.id}
                to={`/זמרים/${s.name.trim()}`}
                style={{
                  color:
                    props.currentSongId == props.item.id ? "white" : "black",
                }}
              >
                {s.name.trim()}{" "}
              </NavLink>
            ))}{" "}
            </div>
            <strong>
              <NavLink
                style={{
                  color:
                    props.currentSongId == props.item.id ? "white" : "black",
                }}
                to={`/שירים/${props.item.singers[0].name.trim()}/${props.item.songName.trim()}`}
              >
                {" "}
                {props.item.songName}{" "}
              </NavLink>
            </strong>
            {!props.isPlaylist&&<>
        
        
            <div className="centerRow" style={{justifyContent:"start"}}>
                          
                    {props.item.playlists.map((p) => (
                      <NavLink
                        key={p}
                        to={`/פלייליסטים/${getPlaylistName(p)}`}
                        className="smallPlaylists"
                      >
                        {getPlaylistName(p) + " "}
                      </NavLink>
                  
                    ))}

                <AddPlaylistRow song={props.item} ifAdmin={true} />
                 
          </div>
          </>}
          </div>
        </div>

        <div className="rowToClumn openSans" style={{fontSize:12}}>
          <div className="centerRow">          <div>
            {props.user ? (
              props.user?.songLikes?.includes(props.item.id) ? (
                <FaHeart onClick={() => likeClick()} />
              ) : (
                <FaRegHeart onClick={() => likeClick()} />
              )
            ) : (
              <LikeLogin />
            )}
          </div>
          <div>{props.item.likes.length} אהבו</div>|
          <div>
            <FaHeadphonesAlt /> שמעו {props.item.played} פעמים
          </div></div>
<div className="centerRow"> 
        {!props.isPlaylist&&
          <div className="centerRow"> 
        
        
        
          <strong
            className="smallPlaylists"
            onClick={() => addToPlaylist(props.item, false)}
          >
            הוסף לרשימה
          </strong>
          <strong
            className="smallPlay"
            onClick={() => addToPlaylist(props.item, true)}
          >
            הפעל
            <div className="play-button"></div>
          </strong>
          </div>}
        </div></div>
 
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allPlaylists: state.allPlaylists,
    ifAdmin: state.ifAdmin,
    user: state.user,
    currentSongId: state.currentSongId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateSongs: (allSongs) => {
      dispatch({
        type: "UPDATE_SONGS",
        payload: allSongs,
      });
    },
    updateCurrentSongId: (songId) => {
      dispatch({
        type: "UPDATE_CURRENT_SONGID",
        payload: songId,
      });
    },
    updatePlaylist: (song) => {
      dispatch({
        type: "ADD_TO_PLAYLIST",
        payload: song,
      });
    },
    
    updateCurrentPlaylistName: (p) => {
      dispatch({
        type: "UPDATE_CURRENT_PLAYLIST_NAME",
        payload: p,
      });
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SongRow);
