import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import backgroundImage  from '../img/Background.jpg'
import Parallax from 'react-rellax'
import { NavLink,useNavigate  } from "react-router-dom";
import Search from "../Search";
import SongRow from "../SongRow";
import SingerRow from "../components/Singers/SingerRow";
import SingerRowFull from "../components/Singers/SingerRowFull";
function FirstScreen(props) {

  useEffect(() => {
    document.title = 'היכל הנגינה - עולם המוזיקה החרדי';
  }, []);
  const navigate = useNavigate();
  const searchBtn =()=>{
    navigate(searchType+"/"+"חיפוש/"+searchTxt)
  }
const [searchType,setSearchType] =useState("זמרים")
const [searchTxt,setSearchText]= useState("")
const clickstartPlaylist=(p)=>{
  props.updateCurrentPlaylist(p)
const songs =props.allSongs.filter(s=>p.songs.includes(s.id))
props.startPlaylist(songs)
props.updateCurrentSongId(songs[0]?.id)
navigate("/רשימת-השמעה")
}
const singersTxt= props.allSingers?.filter(s=>s.name?.includes(searchTxt)).slice(0,5).map(s=>
        <NavLink key={s.id} to={`/זמרים/${s.name}`}> 
        {s.name}  
     </NavLink>) 
const songsTxt= props.allSongs?.filter(s=>s.songName.includes(searchTxt)).slice(0,3).map(s=>

       <NavLink key={s.id} to={`/שירים/${s.singers[0].name?.trim()}/${s.songName.trim()}`}> 
       {s.singers.map(si=>si.name)}{" - "}
        {s.songName}  
    </NavLink>
   )
const playlistsTxt= props.allPlaylists?.filter(p=>p.playlistName.includes(searchTxt)).slice(0,5).map(p=>
        <NavLink key={p.playlistId} to={`/פלייליסטים/${p.playlistName?.trim()}`}> 
        {p.playlistName}  
     </NavLink>)
  return ( 
    <>
          <div className="hero" style={{ backgroundImage: `url(${backgroundImage})` }}>
  <div>
  
  <Parallax speed={5} horizontal={true}>
    
  
<div className="bigTitle">
  <h1  style={{color:"#051a69",margin:0,fontSize:"12vh"}}>
  היכל הנגינה</h1>
 <div style={{marginTop:0}}>
 עולם של מוזיקה חסידית</div>
  </div></Parallax>

<div style={{padding:"0 5vw"}}>
<Parallax speed={2} horizontal={true}>

  אלפי שירים מחכים לך,
  מה תרצו לשמוע?</Parallax></div>
  <div className="searchBox">
  <div className="searchTabs">
    <div className={searchType=="זמרים"?"searchTabsActive":"searchTabsinactive"} onClick={()=>setSearchType("זמרים")}>זמר</div>
    <div className={searchType=="שירים"?"searchTabsActive":"searchTabsinactive"} onClick={()=>setSearchType("שירים")}>שיר</div>
    <div className={searchType=="פלייליסטים"?"searchTabsActive":"searchTabsinactive"} onClick={()=>setSearchType("פלייליסטים")}>פלייליסט</div>
  </div>
  <Search searchTxt={searchTxt} updateSearchTxt={e=>setSearchText(e)} clickBtn={()=>searchBtn()}/>
 <div className="txtUnderSearch mobileHide">
 {searchType=="זמרים"&&singersTxt}
      {searchType=="זמרים"&&<NavLink to={`/זמרים`}> 
       כל הזמרים 
     </NavLink>}
  {searchType=="שירים"&&songsTxt}
  {searchType=="שירים"&&<NavLink to={`/שירים`}> 
       כל השירים 
     </NavLink>}
  {searchType=="פלייליסטים"&&playlistsTxt}
  {searchType=="פלייליסטים"&&<NavLink to={`/פלייליסטים`}> 
       כל הפלייליסטים 
     </NavLink>}
 </div>  
 </div>
</div>
<div>
</div>
    </div>
    <div className="centerColumn">
      <h2>חדשות המוסיקה החסידית - השירים החדשים</h2>
      <div className="songsOgSinger">
          {props.allSongs?.slice(0,5).map((s, i) => {
            return (
             
                  <SongRow key={i} item={s} />
            );
          })}
        </div>
      <h2>השירים הטובים ביותר</h2>

        <div className="songsOgSinger">
          {props.allSongs?.slice().sort((a, b) => b.played - a.played).slice(0,10).map((s, i) => {
            return (
             
                  <SongRow key={i} item={s} />
            );
          })}
        </div>
        <NavLink className="btnAllSingSongs" to={`/שירים`}> 
      
        לכל השירים
     </NavLink>
     <br/>
     <br/>
     <br/>
     <h2>הזמרים החסידיים המובילים</h2>
     <div className="songsOgSinger">
  {props.allSingers?.slice().sort((a, b) => b.played - a.played).slice(0,10).map((s, i) => {
    return (
     
          <SingerRowFull key={i} item={s} allSongs={props.allSongs} />
    );
  })}
</div>
<NavLink className="btnAllSingSongs" to={`/זמרים`}> 
      
      לכל הזמרים
   </NavLink>
   <br/>
     <br/>
     <br/>
     <h2>רשימות השמעה מומלצות</h2>
     <div className="centerColumn">
 {props.allPlaylists.filter(p=>[2,4,16,19,12,1,7].includes(p.playlistId))?.map(p=>
        <div key={p.playlistId} className="songRow" style={{fontSize:30,width:"60vw",padding:20}}>
                          <NavLink
                            to={p.playlistName}
                          >
                       {p.playlistName}
                          </NavLink>
        {/* {" "} | {" "}
        {p.songs.length} שירים */}
      {/* {console.log(p)} */}
      <strong
            className="smallPlay"
            onClick={()=>clickstartPlaylist(p)}
          >
            הפעל
            <div className="play-button"></div>
          </strong>

        </div>
      )}
      
</div>
<NavLink className="btnAllSingSongs" to={`/פלייליסטים`}> 
      
      לכל הפלייליסטים
   </NavLink>
        </div>

        
    </>
  );
}


const mapStateToProps = (state) => {
  return {
    allPlaylists:state.allPlaylists,
    user:state.user,
    allSongs:state.allSongs,
    allSingers:state.allSingers
  };
};


export default connect(mapStateToProps)(FirstScreen);

