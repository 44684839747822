import React, { useState, useEffect, useRef } from "react";
import YouTube, { YouTubePlayer } from "react-youtube";
import { connect } from "react-redux";
import Slider from "@mui/material/Slider";
import PlaylistSongRow from "./components/Playlist/PlaylistSongRow";
import { FaVolumeUp, FaVolumeDown } from "react-icons/fa";
import { NavLink, useLocation } from "react-router-dom";
import ReactPlayer from "react-player";
import apiService from "./services/apiService";
import { ToastContainer, toast,Slide, Zoom, Flip, Bounce } from "react-toastify";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

let videoElement = null;
function Playlist(props) {
  var cElement = null;
  const location = useLocation();

  const [showBig, setShowBig] = useState(false);
  useEffect(() => {
    if (decodeURIComponent(location.pathname.split("/")[1]) == "רשימת-השמעה") {
      setShowBig(true);
    } else {
      setShowBig(false);
    }
  }, [location]);
  const [isRunning, setIsRunning] = useState(true);
  const [volume, setVolume] = useState(0.5);
  const opts = {
    width: "100%",
    playerVars: {
      autoplay: 1,
    },
  };
  const [elapsedTime, setElapsedTime] = useState(0);

  const [currentTime, setCurrentTime] = useState(0);
  const [currentDuration, setCurrentDuration] = useState(0);
  const playerRef = useRef(null);

  useEffect(() => {
    if (props.playlist.length > 0 && !props.currentSongId) {
      props.updateCurrentSongId(props.playlist[0].id);
    }
  }, [props.currentSongId, props.playlist]);

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
  };

  const onError = (event) => {
    toast.error("error:::", event);
  };
  const _onReady = (event) => {
    setElapsedTime(0);
    playerRef.current = event.player;
    setIsRunning(event.player.isPlaying);
  };
  const formatTime = (seconds) => {
    if (seconds) {
      const minutes = Math.floor(seconds / 60); // לחשב את הדקות
      const remainingSeconds = Math.floor(seconds % 60); // לחשב את השניות שנותרו
      return `${String(minutes).padStart(2, "0")}:${String(
        remainingSeconds
      ).padStart(2, "0")}`;
    }
  };
  const handleProgress = (progress) => {
    setCurrentTime(progress.playedSeconds);
  };
  
  const currentSong = props.playlist?.find((p) => p.id == props.currentSongId);

  const clickReplaceSong = async (i, ifEnd) => {
    const index = props.playlist.findIndex(
      (song) => song.id === props.currentSongId
    );
    if (props.playlist.length > index + i && index + i >= 0) {
      updateHistory();
      props.updateCurrentSongId(props.playlist[index + i]?.id);
      return;
    } else {
      if (ifEnd) {
        updateHistory();        
      }

      if(props.playlist.length== index + i&& props.playlist.length>1){
        props.updateCurrentSongId(props.playlist[0]?.id)
      }
    }
  };

  useEffect(() => {
    let intervalId;

    if (isRunning && currentTime > 1) {
      intervalId = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }
  }, []);
  const updateHistory = async () => {
    console.log(props.user)
    const data = {
      userToken: props.user ? props.user.Token : "",
      playlistId: props.currentPlaylist?.playlistId,
      songId: currentSong.id,
      duration: elapsedTime,
    };

    try {
      const response = await apiService.postHistory(data);
      setElapsedTime(0);
      setCurrentTime(0);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    let intervalId;
    if (isRunning > 0) {
      intervalId = setInterval(() => {
        setElapsedTime((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(intervalId);
    }
    return () => clearInterval(intervalId);
  }, [isRunning]);

  const thumbnailUrl = `https://img.youtube.com/vi/${currentSong?.youTubeId?.trim()}/hqdefault.jpg`;
  const handleSeek = (e) => {
    playerRef.current.seekTo(e.target.value);
  };

  
  useEffect(() => {
    setIsRunning(true)
    if(props.currentSongId&&props.playlist)
    {
      setCurrentDuration(currentSong.duration)
    if(playerRef.current){
      playerRef.current.seekTo(currentSong.startAt);
    }

    }
    }, [props.currentSongId,playerRef.current]);
    const onDragEnd = (result) => {
      if (!result.destination) {
        return;
      }
  
      const items = Array.from(props.playlist);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
  
      props.startPlaylist(items);
    };
  return (
    <>
    <div className={showBig?"hide": "playlist"}>
      {currentSong && (
        <>
        מתנגן עכשיו:
          <div>
            <span 
            className="overflowEllipsis" >
            {currentSong?.singers?.map((s) => s.name)}
            </span>
            <NavLink
              to={`/שירים/${currentSong.singers[0].name?.trim()}/${currentSong.songName.trim()}`}
            >
              {" - "}
              <strong> {currentSong?.songName}</strong>
            </NavLink>
          </div>
  
          <div className="centerColumn" >
            <div className="row" style={{ fontSize: 30,marginTop:-8 }}>
              
     
              <div className="pointer" onClick={() => clickReplaceSong(1)}>
                ⏭{" "}
              </div>
              {isRunning ? (
                <div
                  className="pointer"
                  onClick={() => setIsRunning(!isRunning)}
                >
                  ⏸{" "}
                </div>
              ) : (
                <div
                  className="pointer"
                  style={{ color: "#FD6F01" }}
                  onClick={() => setIsRunning(!isRunning)}
                >
                  ⏵{" "}
                </div>
              )}
            </div>
            
</div>
{!showBig && (
            <div className="addPlaylists" style={{fontWeight:"700"}}>
              <NavLink
                style={{ color: "white", fontSize: 13 }}
                to="רשימת-השמעה"
              >
                לרשימת השמעה
              </NavLink>
            </div>
          )}
        </>
      )}
    </div>
      <div
        style={{ display: showBig ? "grid" : "none" }}
        className="bigPlaylist"
      >
        <div className="bigPlaylistList">
          <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="songs">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {props.playlist.map((song, index) => (
              <Draggable key={song.id} draggableId={song.id.toString()} index={index}>
                {(provided) => (
                  <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    className="song-item" // סגנון CSS
                    cancel="playlistBtn" // ביטול גרירה על כפתורים
                style={{cursor:"default"}}
                  >
                  
            <PlaylistSongRow  item={song} nextSong={s=>clickReplaceSong(1)}/>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>

        </div>
        <div className="playerContainer">
          <ReactPlayer
            ref={playerRef}
            key={currentSong?.youTubeId}
            url={`https://www.youtube.com/watch?v=${currentSong?.youTubeId?.trim()}`}
            playing={isRunning}
            onPlay={() => setIsRunning(true)}
            onPause={() => setIsRunning(false)}
            onEnded={() => clickReplaceSong(1, true)}
            opts={opts}
            width={"100%"}
            height={"100%"}
            className="youtubeInCard player"
            onReady={_onReady}
            onError={onError}
            onProgress={handleProgress}
            volume={volume}
          />
    <div className= "playlistDown">
        {currentSong && (
          <>
            <strong>
              {props.currentPlaylist?.playlistName ? (
                <NavLink
                  style={{ color: "white", fontSize: 25 }}
                  to={"/פלייליסטים/" + props.currentPlaylist?.playlistName}
                >
                  {props.currentPlaylist?.playlistName}
                </NavLink>
              ):
              <>{props.playlistName}</>}
            </strong>
            <img
              src={thumbnailUrl}
              alt="YouTube video thumbnail"
              className="playlistImg"
              style={{ display: !showBig ? "flex" : "none" }}
            />
            
            <div>
              {currentSong?.singers?.map((s) => s.name)}

              <NavLink
                to={`/שירים/${currentSong.singers[0].name?.trim()}/${currentSong.songName.trim()}`}
              >
                <strong> {currentSong?.songName}</strong>
              </NavLink>
              {!showBig && (
              <div className="showVideoBtn">
                <NavLink
                  style={{ color: "white", fontSize: 13 }}
                  to="רשימת-השמעה"
                >
                  לרשימת השמעה
                </NavLink>
              </div>
            )}
            </div>
     
            <div className="centerColumn" >
              <div className="row" style={{ fontSize: 30 }}>
                
       
                <div className="pointer" onClick={() => clickReplaceSong(1)}>
                  ⏭{" "}
                </div>
                {isRunning ? (
                  <div
                    className="pointer"
                    onClick={() => setIsRunning(!isRunning)}
                  >
                    ⏸{" "}
                  </div>
                ) : (
                  <div
                    className="pointer"
                    style={{ color: "#FD6F01" }}
                    onClick={() => setIsRunning(!isRunning)}
                  >
                    ⏵{" "}
                  </div>
                )}
                <div className="pointer" onClick={() => clickReplaceSong(-1)}>
                  ⏮
                </div>
              </div>

              <div className="centerRow">
                <div style={{ width: 30, fontSize: 12 }}>
                  {formatTime(currentDuration)}
                </div>
                <Slider
                  sx={{
                    width: 300, // מגדיל את הרוחב ל-300 פיקסלים
                    height: 8, // מגדיל את הגובה ל-8 פיקסלים
                    "& .MuiSlider-thumb": {
                      display: "none",
                    },
                  }}
                  value={currentTime}
                  onChange={handleSeek}
                  aria-labelledby="continuous-slider"
                  min={0}
                  max={currentDuration}
                  step={0.01}
                  style={{ width: "200px" }}
                />
                <div style={{ width: 30, fontSize: 12 }}>
                  {formatTime(currentTime)}
                </div>
              </div>
            </div>
            <div className="centerColumn" style={{ fontSize: 14 ,gap:5}}>
            <div className="pointer" onClick={() => setVolume(volume + 0.1)}>
              <FaVolumeUp />
            </div>
            <Slider
              value={volume}
      orientation="vertical"
              onChange={handleVolumeChange}
              min={0}
              max={1}
              step={0.01}
              sx={{
                width: 4, // מגדיל את הרוחב ל-300 פיקסלים
                height: 30, // מגדיל את הגובה ל-8 פיקסלים
                "& .MuiSlider-thumb": {
                  display: "none",
                },
              }}
            />
            <div className="pointer" onClick={() => setVolume(volume - 0.1)}>
              <FaVolumeDown />{" "}
            </div>
          </div>
 
          </>
        )}
      </div>
        </div>
      </div>

      {/* <div>{props.playlist.map(p=>
        <div key={p.id}>{p.songName}</div>
      )}</div> */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    playlist: state.playlist,
    currentSongId: state.currentSongId,
    currentPlaylist: state.currentPlaylist,
    playlistName: state.playlistName,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentSongId: (songId) => {
      dispatch({
        type: "UPDATE_CURRENT_SONGID",
        payload: songId,
      });
    },
    startPlaylist: (songs) => {
      dispatch({
        type: "START_PLAYLIST",
        payload: songs,
      });
    },  

  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Playlist);
