import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
function Search(props) {
  return (
    <div className="searchBtnRow">
    <input className="searchBtnInput" value={props.searchTxt} onChange={e=>props.updateSearchTxt(e.target.value)}/>
    <div className="searchBtn" onClick={()=>props.clickBtn()}>חיפוש</div>
  </div>
  );
}

const mapStateToProps = (state) => {
  return {
    allSongs: state.allSongs,
  };
};
export default connect(mapStateToProps)(Search);
