
const quotes = {
  'אמן רגש': [
 '****, מגדולי אומני הרגש של המוזיקה החסידית, הכנסו כעת והאזינו לשיריו ולצפות בקליפים החדשים ביותר ולכל אורך השנים ',
 'מחפשים מוזיקה חסידית שתיגע לכם בנשמה? ****, אחד היוצרים הבולטים במוזיקה החסידית '
,'****, קול מלא רגש וחום שמגיע ישר לנשמה, מבצע שירים שמשרים חיבור אמיתי ומרגש'
,' ****, קול עוצמתי ומרגש ביצועים מלאים ברגש, שמצליחים לגעת בכל לב וליצור חיבור בלתי נשכח של מוזיקה חסידית'
,'**** - מביא את הסגנון הייחודי שלו למוזיקה החסידית '
,'****, אמן הרגש שהפך לאחד מגדולי הזמר של השנים האחרונות. ביצועים מלאי רגש ונשמה ',
'****, עם קול מרהיב ומגוון ביצועים, מוזיקה חסידית עוצמתית ומרגשת, תמיד עם חיבור לנשמה'
],
  'גדולי הזמר': [
    '**** - גדול הזמרים החסידיים בעולם, בעל קול ייחודי וסגנון מרגש. האזינו ללהיטיו המוכרים וצפו בקליפים החדשים ביותר. חוו את המוזיקה החסידית במיטבה',
  ,'**** - כוכב הזמר החסידי הגדול ביותר עם מגוון מוזיקלי רחב שמלווה אותנו לאורך כל השנים החל ממוזיקה שמחה ומקפיצה ועד מוזיקת נשמה מרגשת '
  ,'****, כוכב הזמר החסידי עם עשרות להיטים שכבשו את הקהל ויישארו תמיד בין הגדולים ביותר במוזיקה היהודית'
  ,'**** הוא אחד מהכוכבים הגדולים במוזיקה החסידית, עם להיטים שלא מפסיקים לרגש ולחבר בין המוזיקה החסידית ללבבות השומעים'
,'****, כוכב הזמר החסידי עם הלהיטים הגדולים לאורך השנים, הופעות מרהיבות ודואטים בלתי נשכחים שממשיכים לרגש ולהפיץ את המוזיקה היהודית ברחבי העולם'],
  'קומזיץ':['**** - אמן הקומזיצים הגדול חוויה מוזיקלית סוחפת ומרגשת עם ****. האזינו לשיריו וצפו בקטעי וידאו מהשירים המרגשים ']
,'פופ':['**** - כוכב הפופ החסידי  האזינו ללהיטיו הסוחפים וצפו בקליפים החדשים ביותר ',
  '**** - כוכב פופ החסידי עם אנרגיה שאי אפשר לעצור האזינו ללהיטים הסוחפים וצפו בקליפים המקפיצים'
  ,'****, כוכב הפופ, עם מגוון להיטים מקפיצים ומרגשים שמביאים שילוב של אנרגיה חסידית וסטייל בינלאומי',
  '****, כוכב הפופ שפרץ אלינו ומאז לא מפסיק להפתיע, לשמח ולהביא מוזיקה פופ-חסידית חדשה וייחודית'
  ,'****, עם הלהיטים הגדולים שלו, מביא מוזיקה חסידית מלאת רגש ועומק, שכבשה את ליבם של מאזינים ברחבי העולם'
]
,'חסידי':['**** - קולו הייחודי של המוזיקה החסידית. האזינו לשיריו המרגשים וצפו בקליפים החדשים ביותר',
  '**** -  האזינו וצפו בקליפים של ****, אחד הכוכבים העולים של המוזיקה החסידית'
  ,'**** - זמר חסידי, מוזיקה חסידית, מוזיקה יהודית, מוזיקה מקפיצה, שירים חסידיים, קליפים'
  ,'**** - כוכב המוזיקה החסידית עם מוזיקה חסידית מקפיצה וסוחפת, החל מהלהיטים הראשונים ועד ללהיטים החמים האחרונים'
  ,'****, כוכב הזמר החסידי עם מוזיקה מקפיצה ומלהיבה שמביאה איתה אנרגיה עוצמתית וסוחפת בכל שיר'
  ,'****, עם מוזיקה חסידית היימיש המרגשת, שמביאה את רוח המסורת והחסידות בצלילים מלאים חום ורגש, וביצועים שמשאירים רושם עז'
  ,'**** מביא למוזיקה ניחוח חסידי וחדשני, עם ביצועים המעוררים חיבור אמיתי בלב המאזינים, ומצליח לשלב את שיריו עם סגנון אישי ומיוחד'
]
,'מקהלה':['**** - המקהלה החסידית הטובה ביותר האזינו לשירים המדהימים וצפו בביצועים המרהיבים'
  ,'המקהלה החסידית **** מציגה ביצועים מרהיבים של מוזיקה חסידית עם חום, עוצמה ורגש '
  ,'**** מבצעת יצירות חסידיות בשירה מלאת חום, תמימות ורגש. עם הקולות המרגשים, היא מצליחה להעביר את רוח החסידות בשיריה'
]
,'קצבי' :['**** - קולו הייחודי של המוזיקה החסידית הקצבית. האזינו ללהיטים הגדולים וצפו בקליפים החדשים ביותר '
  ,'**** אחד הכוכבים העולים במוזיקה היהודית, עם קול ייחודי וביצועים מלאים באנרגיה ',
  '****, מוזיקה מקפיצה וסוחפת שמביאה אנרגיה חדשה ומרעננת בכל שיר',
  '**** עם ביצועים מקצועיים ומרגשים שמביאים את המוזיקה החסידית לרמה חדשה '
  ,'**** זמר חסידי קצבי, שמביא עוצמה ושמחה בכל שיר. עם קול מלא אנרגיה ושירים קצביים'
],
'רגיל':[' השירים והקליפים לצפייה והאזנה של הזמר ****. האזינו  וצפו בקליפים','האזינו וצפו בקליפים של הזמר ****'
  ,' דף השירים של הזמר ****'
]
,'מזרחי':['הזמר **** מביא למוזיקה ניחוח מזרחי ייחודי, ']
,'ילד הפלא' :['ילד הפלא **** עם שירים חסידיים מלאים ברגש ותחושת תמימות. ','****  ילד פלא במוזיקה החסידית, מרגש כל מי ששומע אותו בשירה עוצמתית, עם כשרון מוזיקלי נדיר ויכולת להביא רגש אמיתי']

};

const quotes2 = {
  
  'אמן רגש': [    
'חוו את קולו הייחודי וסגנונו הסוחף. הצטרפו לחוויית מוסיקה חסידית מרגשת' ,
'מזמין אתכם להאזין לשיריו המרגשים',
'האזינו לשיריו וחוו את השילוב הייחודי של מוזיקה חסידית ורגש'
     ],
  'גדולי הזמר': [
'המוזיקה החסידית במיטבה' ],
'קומזיץ': [    
  'חוו את קולו הייחודי וסגנונו המיוחד. הצטרפו לחוויית קומזיץ מרגשת' ,'חוויה מוזיקלית מושלמת'
       ]
       ,'פופ':['חוויה מוזיקלית מושלמת','**** - תהנו ממוסיקת הפופ  עם אחד מכוכבי הזמר החסידי הגדולים','מוזיקה שתגרום לכם לרקוד']
       ,'חסידי':['חוויה מוזיקלית מושלמת','  חוו את הסגנון הסוחף של ****',' **** - אמן מוזיקה חסידית אותנטית האזינו וצפו']
,'מקהלה':['חוויה מוזיקלית מושלמת'
  ,'עם היצירות המרגשות שלה, היא מצליחה להביא את רוח החסידות לכל לב'
],
'קצבי':['חוו את הסגנון הסוחף של ****','האזינו לשיריו ותהנו מחוויה מוזיקלית חסידית',
  'קול ייחודי שמצליח להרים ולהלהיב, עם כל הלהיטים הגדולים של המוזיקה החסידית'
  ,'שיריו מבוצעים באומנות ובדיוק, ומספקים חוויה מוזיקלית שאין שני לה'
  ,'שיריו המקפיצים משרים אווירה חגיגית ומרוממת'
]
,'רגיל':[' חוו את המוזיקה החסידית של ****','להנאה מירבית הכנסו כעת','בואו לנסות את שיריו של ****']
,'מזרחי':[' עם ביצועים המעוררים חיבור אמיתי בלב המאזינים','מוזיקה מזרחית במיטבה']
,'ילד הפלא' :[' עם קול מלא חום ומילים שמגיעות ישר מהלב אל הלב']
      };





export async function getDescriptionForSinger(singerName, singerStatus) {
  const randomQuotes = [];
    let randomIndex = Math.floor(Math.random() * quotes[singerStatus]?.length);
    randomQuotes.push(quotes[singerStatus][randomIndex]);
    randomIndex = Math.floor(Math.random() * quotes2[singerStatus]?.length);
    randomQuotes.push(quotes2[singerStatus][randomIndex]);
  
  const singerTxt = `${randomQuotes[0]}. ${randomQuotes[1]}!`;
return singerTxt.replaceAll("****",singerName)
}

export function getStatuses(){
 return Object.keys(quotes);}
export default {
  getDescriptionForSinger,
  getStatuses
};
