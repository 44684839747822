import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useParams,useNavigate,NavLink } from "react-router-dom";
import Search from '../Search'
import EditPlaylist from "../EditPlaylist";
import { Helmet } from 'react-helmet';
function AllplaylistScreen(props) {
  const navigate = useNavigate();
const clickstartPlaylist=(p)=>{
  props.updateCurrentPlaylist(p)
const songs =props.allSongs.filter(s=>p.songs.includes(s.id))
props.startPlaylist(songs)
props.updateCurrentSongId(songs[0]?.id)
navigate("/רשימת-השמעה")
}
const { searchTxt } = useParams();
const [search,setSearch]= useState("")

useEffect(() => {
  if(searchTxt){
    setSearch(searchTxt)
  }
}, [searchTxt]);

const results=search.length>0?props.allPlaylists.filter(p=>p.playlistName.includes(search)):props.allPlaylists

  return ( 
    <div className="centerColumn">
      <Helmet>
  <title>  היכל הנגינה :: רשימת הפלייליסטים</title>
  <meta name="description" content="גלה את כל רשימות ההשמעה המגוונות שלנו – ממוזיקה חסידית שמחברת את הלב ועד שירים מרגשים שממלאים את הנפש. כל רשימה נבחרה בקפידה להציע את השירים הטובים ביותר עבור כל רגע. הצטרפו עכשיו למגוון עשיר של סגנונות ושירים שמחכים רק לכם!" />
</Helmet>
      <h1>
      פלייליסטים</h1>
              <Search searchTxt={search} updateSearchTxt={e=>setSearch(e)} clickBtn={()=>undefined}/>
                <br/>
                <br/>
      <div>
      {results?.map(p=>
        <div key={p.playlistId} className="songRow" style={{fontSize:30,width:"60vw",padding:20}}>
                          <NavLink
                            to={p.playlistName}
                          >
                     {p.playlistName} -  {p.title}
                          </NavLink>
        {/* {" "} | {" "}
        {p.songs.length} שירים */}
      {/* {console.log(p)} */}
      {p.description?
  <div   style={{fontSize:22}}>    {p.description}
    </div>
:
    <></>}
    {/* // <EditPlaylist playlist={p}/>} */}
      <strong
            className="smallPlay"
            onClick={()=>clickstartPlaylist(p)}
          >
            הפעל
            <div className="play-button"></div>
          </strong>

        </div>
      )}
      
    </div></div>
  );
}


const mapStateToProps = (state) => {
  return {
    allPlaylists:state.allPlaylists,
    user:state.user,
    allSongs:state.allSongs
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateCurrentPlaylist: (p) => {
      dispatch({
        type: "UPDATE_CURRENT_PLAYLIST",
        payload: p,
      });
    },  
    updateCurrentSongId: (songId) => {
      dispatch({
        type: "UPDATE_CURRENT_SONGID",
        payload: songId,
      });
    },  
    startPlaylist: (songs) => {
      dispatch({
        type: "START_PLAYLIST",
        payload: songs,
      });
    },  
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(AllplaylistScreen);

